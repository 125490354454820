import { useEffect } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import BeginningWith from "./components/BeginningWith/BeginningWith";
import EyesSection from "./components/EyesSection/EyesSection";

import { useSetAtom } from "jotai";
import { darkModeAppbarAtom } from "../angeleye/atoms/darkModeAppbarAtom";
import Header from "./components/Header/Header";
import HeartSection from "./components/HeartSection/HeartSection";
import IncentivizeProfile from "./components/IncentivizeProfile/IncentivizeProfile";
import ResearchTestRepeat from "./components/ResearchTestRepeat/ResearchTestRepeat";
import SlideSection from "./components/SlideSection/SlideSection";
import TextSection from "./components/TextSection/TextSection";
const District = () => {
  const setDarkModeAppbar = useSetAtom(darkModeAppbarAtom);

  useEffect(() => {
    setDarkModeAppbar({
      darkMode: true,
      backgroundColor: "#555BFF",
    });

    return () => {
      setDarkModeAppbar({
        darkMode: false,
      });
    };
  }, []);

  return (
    <div className="overflow-x-hidden bg-[#555BFF]">
      <Header />
      <TextSection />
      <SlideSection />
      <HeartSection />
      <EyesSection />
      <BeginningWith />
      <IncentivizeProfile />
      <ResearchTestRepeat />
      <div className="bg-white py-[80px] md:py-[200px]">
        <PrevNextCaseStudy
          sx={{
            marginTop: { md: "0px", xs: "0px" },
            marginBottom: { md: "0px", xs: "0px" },
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default District;
