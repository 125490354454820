import { animated, easings, useSpring } from "react-spring";
// @ts-ignore
import scrollArrow from "./images/swipe_left_arrow.png";
// @ts-ignore
import scrollHand from "./images/swipe_left_hand.png";

const SlideScrollButtonSmall = () => {
  const [props] = useSpring(() => ({
    from: { x: "-50%", rotate: 25 },
    to: { x: "-50%", rotate: -25 },
    loop: true,
    config: { duration: 2000, easing: easings.easeInOutCirc },
  }));

  return (
    <div>
      <div className="relative mx-auto mt-10 h-[80px] w-[80px] sm:h-[100px] sm:w-[100px]">
        <div className="absolute bottom-0 left-1/2 aspect-square w-[80px] -translate-x-1/2 rounded-full bg-[radial-gradient(ellipse_at_center,rgba(255,255,255,0.24),rgba(255,255,255,0))] blur-lg sm:w-[100px]"></div>
        <img
          src={scrollArrow}
          className="absolute left-1/2 top-0 w-[65px] -translate-x-1/2 sm:w-[80px]"
        />
        <animated.div
          className="absolute bottom-0 left-1/2 aspect-square h-fit w-[50px] origin-bottom -translate-x-1/2 sm:w-[62px]"
          style={props}
        >
          <img src={scrollHand} className="mt-auto h-full min-h-full w-full" />
        </animated.div>
      </div>
    </div>
  );
};

export default SlideScrollButtonSmall;
