import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";

import District from "../../features/case_study/district";
import { l_district } from "../../features/case_study/district/locales";

export default District;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_district("seo.title")}
      description={l_district("seo.description")}
      image="/case_studies_png/cover-district.png"
      headProps={headProps}
    />
  );
};
