// @ts-ignore
import hrtLg from "./images/heart_1.png";
// @ts-ignore
import hrtMd from "./images/heart_2.png";
import { useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { cn } from "../../../../../utils/cn";
// @ts-ignore
import hrtSm from "./images/heart_3.png";

const BeatingHeart = ({ isCompressed }: { isCompressed: boolean }) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isHovered, setIsHovered] = useState(false);

  const shouldAnimate = (isOverMd && isHovered) || isCompressed;
  return (
    <div
      className={cn(
        "flex shrink-0 items-center justify-center transition-all duration-500 md:basis-[30%]",
        isCompressed ? "basis-[150px]" : "basis-[250px]",
      )}
    >
      <div
        className="relative aspect-[274/252] h-full md:h-auto md:w-full"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <img src={hrtLg} className="absolute left-0 top-0 h-full w-full" />
        <img
          src={hrtMd}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700 ease-in-out",
            shouldAnimate ? "scale-[90%]" : "scale-[80%]",
          )}
        />
        <img
          src={hrtSm}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700 ease-in-out",
            shouldAnimate ? "scale-[80%]" : "scale-[50%]",
          )}
        />
        <img
          src={hrtLg}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform",
            shouldAnimate
              ? "scale-[50%] duration-700 ease-in-out"
              : "scale-[0%] duration-500",
          )}
        />
        <img
          src={hrtMd}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform",
            shouldAnimate
              ? "scale-[35%] duration-700 ease-in-out"
              : "scale-[0%] duration-500",
          )}
        />
        {/* // ? Another version for the same animation */}
        {/* <img src={hrtLg} className="absolute left-0 top-0 h-full w-full" />
        <img
          src={hrtMd}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700",
            shouldAnimate ? "scale-[100%]" : "scale-[80%]",
          )}
        />
        <img
          src={hrtSm}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700",
            shouldAnimate ? "scale-[100%]" : "scale-[50%]",
          )}
        />
        <img
          src={hrtLg}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700",
            shouldAnimate ? "scale-[100%]" : "scale-[0%]",
          )}
        />
        <img
          src={hrtMd}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700",
            shouldAnimate ? "scale-[80%]" : "scale-[0%]",
          )}
        />
        <img
          src={hrtSm}
          className={cn(
            "absolute left-0 top-0 h-full w-full transition-transform duration-700",
            shouldAnimate ? "scale-[50%]" : "scale-[0%]",
          )}
        /> */}
      </div>
    </div>
  );
};

export default BeatingHeart;
