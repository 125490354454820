import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { animated } from "react-spring";
import { cn } from "../../../../../utils/cn";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";

const AscendingCard = ({
  isShowing,
  closeCard,
}: {
  isShowing: boolean;
  closeCard: () => void;
}) => {
  return (
    <animated.div
      className={cn(
        "absolute left-0 top-0 z-30 box-border flex h-full w-full items-center justify-center rounded-2xl transition-transform duration-700 ease-in-out md:p-4",
        isShowing
          ? "translate-y-0"
          : "translate-y-[calc(100%+40px)] md:translate-y-full",
      )}
      // style={props}
    >
      <div className="relative box-border h-full max-h-full w-full overflow-hidden rounded-lg bg-[#555BFF] p-4 md:p-8">
        <IconButton
          size="small"
          className="absolute right-2 top-2"
          onClick={closeCard}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
        <div className="flex h-full flex-col justify-center">
          <DistrictTypography
            variant="title2"
            className="text-[clamp(14px,4vw,24px)] leading-[clamp(18px,calc(4vw+4px),32px)] lg:text-[24px] lg:leading-8"
          >
            {l_district("heartSection.insideMessage")}
          </DistrictTypography>
        </div>
      </div>
    </animated.div>
  );
};

export default AscendingCard;
