// @ts-ignore
import yellowLineLg from "./images/yellow_line_lg.png";
// @ts-ignore
import yellowLineSm from "./images/yellow_line_sm.png";
// @ts-ignore
import eyeDecoration from "../TextSection/images/eye_decoration.png";
// @ts-ignore
import whiteOctagon from "./images/white_octagon.png";
// @ts-ignore
import greenCircle from "./images/green_circle.png";

import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { animated, easings, useSpring } from "react-spring";
import { cn } from "../../../../../utils/cn";

export const YellowLineDecorationBig = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const [shouldHideLine, setShouldHideLine] = useState(false);

  const [eyeDec, eyeDecApi] = useSpring(() => {
    return {
      from: {
        right: "40%",
        top: "60%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerEyeDec = () => {
    eyeDecApi.start({
      to: [
        {
          right: "42%",
          top: "20%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          right: "60%",
          top: "45%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          right: "40%",
          top: "60%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  const [octDec, octDecApi] = useSpring(() => {
    return {
      from: {
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerOctDec = () => {
    octDecApi.start({
      to: [
        {
          delay: 1000,
          rotate: 360,
          config: {
            duration: 2000,
          },
        },
        {
          rotate: -180,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          rotate: 0,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  const [grnCrl, grnCrlApi] = useSpring(() => {
    return {
      from: {
        scale: 1,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerGrnCrl = () => {
    grnCrlApi.start({
      to: [
        {
          scale: 1.1,
          delay: 4000,
          config: {
            duration: 2000,
          },
          onStart: () => {
            setShouldHideLine(true);
          },
        },
        {
          scale: 1,
          delay: 1000,
          config: {
            duration: 2000,
          },
          onStart: () => {
            setShouldHideLine(false);
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  useEffect(() => {
    eyeDecApi.stop();
    triggerEyeDec();
    octDecApi.stop();
    triggerOctDec();
    grnCrlApi.stop();
    triggerGrnCrl();
  }, []);

  return (
    <div
      className="absolute right-0 top-0 isolate aspect-[1450/1556] overflow-hidden"
      style={{ width: width, height: height }}
    >
      <img src={yellowLineLg} className="relative z-[1] h-full w-full" />
      <animated.div
        className={"absolute right-1/3 top-1/2 z-[2] aspect-[140/83] w-[20%]"}
        style={eyeDec}
      >
        <img src={eyeDecoration} className="h-full w-full" />
      </animated.div>
      <animated.div
        className={"absolute left-[10%] top-[10%] z-[2] aspect-[1/1] w-[15%]"}
        style={octDec}
      >
        <img src={whiteOctagon} className="h-full w-full" />
      </animated.div>
      <div className="absolute right-[10%] top-[55%] z-[2] aspect-square w-[20%]">
        <img
          src={greenCircle}
          className="absolute left-[5%] top-[5%] h-[90%] w-[90%]"
        />
        <animated.div className={"relative h-full w-full"} style={grnCrl}>
          <animated.div
            className={cn(
              "absolute bottom-[90%] left-[105%] z-[2] aspect-[1/6] w-[15%] rotate-[30deg] bg-white transition-all delay-1000 duration-1000",
              shouldHideLine && "opacity-0",
            )}
          />
          <animated.div
            className={cn(
              "absolute bottom-[103%] left-[70%] z-[2] aspect-[1/6] w-[15%] rotate-[15deg] bg-white transition-all delay-1000 duration-1000",
              shouldHideLine && "opacity-0",
            )}
          />
          <animated.div
            className={cn(
              "absolute bottom-[103%] left-[20%] z-[2] aspect-[1/6] w-[15%] rotate-[-15deg] bg-white transition-all delay-1000 duration-1000",

              shouldHideLine && "opacity-0",
            )}
          />
          <animated.div
            className={cn(
              "absolute bottom-[90%] left-[-15%] z-[2] aspect-[1/6] w-[15%] rotate-[-30deg] bg-white transition-all delay-1000 duration-1000",
              shouldHideLine && "opacity-0",
            )}
          />
        </animated.div>
      </div>
      <animated.div
        className={"absolute left-[10%] top-[10%] z-[2] aspect-[1/1] w-[15%]"}
        style={octDec}
      >
        <img src={whiteOctagon} className="h-full w-full" />
      </animated.div>
    </div>
  );
};

export const YellowLineDecorationSmall = () => {
  const [shouldHideLine, setShouldHideLine] = useState(false);

  const [eyeDec, eyeDecApi] = useSpring(() => {
    return {
      from: {
        right: "10%",
        top: "50%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });
  const triggerEyeDec = () => {
    eyeDecApi.start({
      to: [
        {
          right: "20%",
          top: "20%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          right: "60%",
          top: "60%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          right: "10%",
          top: "50%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  const [octDec, octDecApi] = useSpring(() => {
    return {
      from: {
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });
  const triggerOctDec = () => {
    octDecApi.start({
      to: [
        {
          delay: 1000,
          rotate: 360,
          config: {
            duration: 2000,
          },
        },
        {
          rotate: -180,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          rotate: 0,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };
  const [grnCrl, grnCrlApi] = useSpring(() => {
    return {
      from: {
        scale: 1,
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });
  const triggerGrnCrl = () => {
    grnCrlApi.start({
      to: [
        {
          scale: 1.3,
          rotate: -30,
          delay: 4000,
          config: {
            duration: 2000,
          },
          onStart: () => {
            setShouldHideLine(true);
          },
        },
        {
          scale: 1,
          rotate: 0,
          delay: 1000,
          config: {
            duration: 2000,
          },
          onStart: () => {
            setShouldHideLine(false);
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  useEffect(() => {
    eyeDecApi.stop();
    triggerEyeDec();
    octDecApi.stop();
    triggerOctDec();
    grnCrlApi.stop();
    triggerGrnCrl();
  }, []);

  return (
    <div className="relative mb-10">
      <img src={yellowLineSm} className="w-full" />
      <animated.div
        className={"absolute right-[10%] top-1/2 z-[2] aspect-[140/83] w-[15%]"}
        style={eyeDec}
      >
        <img src={eyeDecoration} className="h-full w-full" />
      </animated.div>
      <animated.div
        className={"absolute left-[10%] top-[20%] z-[2] aspect-[1/1] w-[12%]"}
        style={octDec}
      >
        <img src={whiteOctagon} className="h-full w-full" />
      </animated.div>
      <animated.div
        className="absolute bottom-0 right-[25%] z-[2] aspect-square w-[10%] rotate-[-30deg]"
        style={grnCrl}
      >
        <img
          src={greenCircle}
          className="absolute left-[5%] top-[5%] h-[90%] w-[90%]"
        />
        {/* <animated.div className={"relative h-full w-full"} style={grnCrl}> */}
        <animated.div
          className={cn(
            "absolute bottom-[90%] left-[105%] z-[2] aspect-[1/6] w-[15%] rotate-[30deg] bg-white transition-all delay-1000 duration-1000",
            shouldHideLine && "opacity-0",
          )}
        />
        <animated.div
          className={cn(
            "absolute bottom-[103%] left-[70%] z-[2] aspect-[1/6] w-[15%] rotate-[15deg] bg-white transition-all delay-1000 duration-1000",
            shouldHideLine && "opacity-0",
          )}
        />
        <animated.div
          className={cn(
            "absolute bottom-[103%] left-[20%] z-[2] aspect-[1/6] w-[15%] rotate-[-15deg] bg-white transition-all delay-1000 duration-1000",

            shouldHideLine && "opacity-0",
          )}
        />
        <animated.div
          className={cn(
            "absolute bottom-[90%] left-[-15%] z-[2] aspect-[1/6] w-[15%] rotate-[-30deg] bg-white transition-all delay-1000 duration-1000",
            shouldHideLine && "opacity-0",
          )}
        />
      </animated.div>
      {/* </animated.div> */}
    </div>
  );
};

const YellowLineDecoration = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return isOverMd ? (
    <YellowLineDecorationBig width={width} height={height} />
  ) : (
    <YellowLineDecorationSmall />
  );
};

export default YellowLineDecoration;
