// import Lottie from "lottie-react";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";
// import Lottie from "lottie-react";
import eyesAnimation from "./images/eyes_lottie.json";

const Lottie = React.lazy(() => import("lottie-react"));

import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
// @ts-ignore
import darkBgBig from "./images/bg_large.png";

const EyesSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const isSSR = typeof window === "undefined";

  return (
    <div className="isolate mx-auto mb-10 box-border w-full max-w-[1100px] overflow-hidden px-4 md:mt-0">
      <div className="relative mt-0">
        <div className="relative z-10 flex h-full items-center md:static">
          <div className="relative right-0 top-0 z-10 pt-10 md:absolute md:mt-6 md:max-w-[60%] md:pt-0 lg:max-w-[55%]">
            <div className="absolute -bottom-6 z-[2] mt-auto box-border h-full w-full rounded-2xl bg-[#555BFF]/[0.8] blur-[40px] md:bottom-0 md:bg-[#555BFF]/[0.7]"></div>
            <DistrictTypography
              variant="title1"
              className="relative z-10 mb-6 md:mb-5 md:pt-0 md:text-[44px] md:leading-[48px] lg:text-[60px] lg:leading-[64px]"
            >
              {l_district("eyesSection.title")}
            </DistrictTypography>
            <DistrictTypography variant="body" className="relative z-10">
              {l_district("eyesSection.text")}
            </DistrictTypography>
          </div>
        </div>
        {/* <div className="relative left-0 top-0 box-border aspect-[495/242] w-full bg-yellow-300 md:top-0 md:-my-[5%] md:mb-[5%] md:aspect-[1500/737]"> */}
        <div
          className="relative left-0 top-0 box-border aspect-[1500/737] w-full translate-y-[9%] md:top-0 md:-my-[5%] md:mb-[5%] md:translate-y-0"
          style={{
            display:
              "-webkit-inline-flex" /** this is needed to have a right alignment on safari */,
          }}
        >
          <img
            className="absolute bottom-0 left-0 z-[2] box-border aspect-[1500/737] w-[190%] min-w-[500px] rotate-[-1.5deg] md:block md:w-full"
            src={darkBgBig}
          />

          {!isSSR && (
            <React.Suspense fallback={<div />}>
              {/* <div className="absolute bottom-[9%] left-0 z-[3] box-border aspect-[495/242] h-full translate-x-[1px] bg-blue-200 md:w-[37.3%]"> */}
              <Lottie
                animationData={eyesAnimation}
                loop={true}
                style={{
                  position: "absolute",
                  bottom: isOverMd ? "9%" : "9%",
                  left: 0,
                  width: isOverMd ? "37.3%" : "unset",
                  height: isOverMd ? "unset" : "100%",
                  zIndex: 3,
                  boxSizing: "border-box",
                  translate: "0px 0px",
                  // backgroundColor: "#145AA7",
                }}
              />
            </React.Suspense>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EyesSection;
