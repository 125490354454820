import React, { useEffect } from "react";
import { animated, easings, useSpring } from "react-spring";

//@ts-ignore
import carDecoration from "./images/car_decoration.png";
//@ts-ignore
import eyeDecoration from "./images/eye_decoration.png";
//@ts-ignore
import octagonDecoration from "./images/octagon_decoration.png";
//@ts-ignore
import peanutDecoration from "./images/peanut_decoration.png";

const SpinningDecoration = () => {
  const [carDecorationStyle, carDecorationApi] = useSpring(() => {
    return {
      from: {
        top: "0%",
        left: "0%",
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutSine,
      },
    };
  });

  const triggerCarDecoration = () => {
    carDecorationApi.start({
      to: [
        {
          top: "10%",
          left: "75%",
          rotate: 20,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "60%",
          left: "85%",
          rotate: 40,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "65%",
          left: "-5%",
          rotate: -10,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "0%",
          left: "0%",
          rotate: 0,
        },
      ],
      loop: true,
    });
  };

  const [eyeDecorationStyle, eyeDecorationApi] = useSpring(() => {
    return {
      from: {
        top: "0%",
        right: "10%",
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutSine,
      },
    };
  });

  const triggerEyeDecoration = () => {
    eyeDecorationApi.start({
      to: [
        {
          top: "60%",
          right: "5%",
          rotate: -20,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "50%",
          right: "40%",
          rotate: 10,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "10%",
          right: "55%",
          rotate: -30,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "0%",
          right: "10%",
          rotate: 0,
        },
        // {
        //   top: "5%",
        //   left: "10%",
        //   delay: 1000,
        //   config: {
        //     duration: 2000,
        //   },
        // },
        // {
        //   top: "60%",
        //   left: "20%",
        //   delay: 1000,
        //   config: {
        //     duration: 2000,
        //   },
        // },
      ],
      loop: true,
    });
  };

  const [peanutDecorationStyle, peanutDecorationApi] = useSpring(() => {
    return {
      from: {
        top: "50%",
        right: "4%",
        rotate: 45,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutSine,
      },
    };
  });

  const triggerPeanutDecoration = () => {
    peanutDecorationApi.start({
      to: [
        {
          top: "70%",
          right: "55%",
          rotate: 0,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "10%",
          right: "45%",
          rotate: 30,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "10%",
          right: "20%",
          rotate: -40,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "50%",
          right: "4%",
          rotate: 45,
        },
      ],
      loop: true,
    });
  };

  const [octagonDecorationStyle, octagonDecorationApi] = useSpring(() => {
    return {
      from: {
        bottom: "0%",
        left: "0%",
        rotate: 10,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutSine,
      },
    };
  });

  const triggerOctagonDecoration = () => {
    octagonDecorationApi.start({
      to: [
        {
          bottom: "50%",
          left: "10%",
          rotate: 0,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "60%",
          left: "80%",
          rotate: -10,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "0%",
          left: "50%",
          rotate: -45,
          // delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "0%",
          left: "0%",
          rotate: 10,
        },
      ],
      loop: true,
    });
  };

  useEffect(() => {
    carDecorationApi.stop();
    eyeDecorationApi.stop();
    octagonDecorationApi.stop();
    peanutDecorationApi.stop();
    triggerCarDecoration();
    triggerEyeDecoration();
    triggerOctagonDecoration();
    triggerPeanutDecoration();
  }, []);

  return (
    <animated.div
      className={
        "absolute bottom-[0%] right-8 aspect-[7/4] w-[50%] rounded-full sm:w-[30%]"
      }
    >
      <animated.div
        className={"absolute left-0 top-0 w-[30%]"}
        style={carDecorationStyle}
      >
        <img src={carDecoration} className="w-full" />
      </animated.div>
      <animated.div
        className={"absolute right-[10%] top-0 w-[28%]"}
        style={eyeDecorationStyle}
      >
        <img src={eyeDecoration} className="w-full" />
      </animated.div>
      <animated.div
        className={"absolute right-[4%] top-1/2 w-[25%]"}
        style={peanutDecorationStyle}
      >
        <img src={peanutDecoration} className="w-full" />
      </animated.div>
      <animated.div
        className={"absolute bottom-0 left-[10%] w-[20%]"}
        style={octagonDecorationStyle}
      >
        <img src={octagonDecoration} className="w-full" />
      </animated.div>
    </animated.div>
  );
};

export default SpinningDecoration;
