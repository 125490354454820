import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "District",
      description:
        "District è la piattaforma digitale che rivoluziona il recruiting nel settore del design. Abbiamo contribuito ad aumentare l'adesione alla community.",
    },
    header: {
      title1: "Ottimizzazione del recruiting con",
      title2: "cambiamenti orientati al raggiungimento degli obiettivi",
    },
    textSection: {
      overtitle: "Il palcoscenico digitale per il tuo futuro lavorativo",
      section1:
        "Per capire appieno il contesto di District, immaginate uno spazio digitale dove talenti del design e opportunità lavorative si incontrano.",
      section2_1:
        "Combinando i professionisti del settore grazie a una forte community e molte opportunità di ",
      section2_2: "networking",
      section2_3: ".",
    },
    heartSection: {
      coverMessage1: "Il cuore pulsante",
      coverMessage2: "di questa piattaforma",
      coverMessage3: 'è il "design matching"',
      insideMessage:
        "Il team di District ci ha affidato una doppia sfida: ottimizzare le conversioni delle e-mail per incoraggiare una maggiore adesione alla community da parte dei professionisti, e aumentare la partecipazione degli utenti nel condividere i propri portfolio sulla piattaforma, garantendo così un alto livello di qualità nella selezione di talenti per le aziende.",
      buttonText: "Scopri di più",
    },
    eyesSection: {
      title: "Goal framing workshop",
      text: "Spesso, all'inizio delle nostre collaborazioni, ci impegniamo nell'organizzazione di workshop personalizzati, sia online che offline, progettati su misura per soddisfare le specifiche esigenze del cliente. Questi incontri mirano a allineare le aspettative di tutte le persone coinvolte e a stabilire basi solide su cui costruire un percorso di lavoro condiviso.",
    },
    beginningWith: {
      titleA: "L’inizio con District",
      textA_1:
        "Il primo passo è stato un workshop online dedicato alla chiara definizione degli obiettivi per le modifiche alle email. Questo momento di collaborazione è stato cruciale per immergerci completamente nelle aspettative del team District.",
      textA_2:
        "Grazie alla partecipazione attiva e alla condivisione di idee da parte dei partecipanti, abbiamo plasmato insieme un quadro chiaro e condiviso, costruendo così solide fondamenta su cui basare le prossime fasi del nostro lavoro.",
      titleB_1: "Svelare i segreti di un click ",
      titleB_2: "perfetto",
      titleB_3: ".",
      textB_1:
        "Dopo un'attenta analisi dei dati correnti provenienti da Brevo (ex SendinBlue) e il confronto con i benchmark del settore, ci siamo posti l'obiettivo ambizioso di raggiungere 2 click per mail da parte di ogni utente.",
      textB_2:
        "Questa sfida si può considerare particolarmente impegnativa considerando che le mail di District già ottenevano risultati eccellenti rispetto al mercato.",
    },
    incentivizeProfile: {
      cardText: "Incentivare la compilazione del profilo",
      title: "Il secondo fronte di battaglia era la registrazione.",
      text1:
        "Il nostro approccio si è basato sui dati ricavati da Hotjar, un potente strumento per ricevere feedback, che ci ha dato modo di capire chi fossero le user personas e analizzare le sessioni utente attuali.",
      text2:
        "Riprogettando la UI della registrazione abbiamo aumentato la quantità di informazioni richieste, incentivando gli utenti a inserire più dettagli e a condividere il proprio portfolio grazie a degli accorgimenti grafici e all’aggiunta di spiegazioni dettagliate sui benefici delle informazioni aggiuntive.",
      text3:
        "Attraverso test di usabilità online e AB testing, abbiamo potuto validare alcune delle nostre intuizioni, raccogliendo feedback positivi e infine migliorando migliorato il flusso di registrazione.",
    },
    researchTestRepeat: {
      title: "Research, design, test, repeat",
      text: "Ancora una volta il nostro approccio ha dimostrato che lavorare in sinergia con gli utenti, abbracciare il cambiamento e ascoltare le risposte del pubblico sono attività fondamentali per il successo di un prodotto digitale. District ora offre un'esperienza di recruiting più avanzata grazie a modifiche mirate che hanno ridefinito il modo in cui gli utenti interagiscono con la piattaforma.",
    },
  },
  en: {
    seo: {
      title: "District",
      description:
        "District is the digital platform that revolutionizes recruiting in the design sector. We have helped increase community membership.",
    },
    header: {
      title1: "Recruiting optimization with",
      title2: "changes oriented towards goal achievement",
    },
    textSection: {
      overtitle: "The digital stage for your working future",
      section1:
        "To fully understand the context of District, imagine a digital space where design talent and job opportunities meet.",
      section2_1:
        "Combining industry professionals thanks to a strong community and many ",
      section2_2: "networking",
      section2_3: " opportunities.",
    },
    heartSection: {
      coverMessage1: "The beating heart",
      coverMessage2: "of this platform",
      coverMessage3: 'is "design matching"',
      insideMessage:
        "The District team entrusted us with a double challenge: to optimize email conversions to encourage greater participation in the community by professionals, and to increase user participation in sharing their portfolios on the platform, guaranteeing a high level of quality in the selection of talent for companies.",
      buttonText: "Discover more",
    },
    eyesSection: {
      title: "Goal framing workshop",
      text: "Often, at the beginning of our collaborations, we engage in organizing customized workshops, both online and offline, tailor-made to meet the client's specific needs. These meetings aim to align the expectations of all the people involved and establish solid foundations on which to build a shared work path.",
    },
    beginningWith: {
      titleA: "Beginning with District",
      textA_1:
        "The first step was an online workshop dedicated to clearly defining objectives for email changes. This moment of collaboration was crucial to fully immerse ourselves in the District team's expectations.",
      textA_2:
        "Thanks to the active participation and sharing of ideas by the participants, we have together shaped a clear and shared framework, thus building a solid foundation on which to base the next steps of our work.",
      titleB_1: "Unveil the secrets of a ",
      titleB_2: "perfect",
      titleB_3: " click.",
      textB_1:
        "After a careful analysis of current data from Brevo (formerly SendinBlue) and comparison with industry benchmarks, we set ourselves the ambitious goal of reaching 2 clicks per email from each user.",
      textB_2:
        "This challenge can be considered particularly demanding considering that District's emails were already achieving excellent results compared to the market.",
    },
    incentivizeProfile: {
      cardText: "Encourage profile completion",
      title: "The second battlefront was registration.",
      text1:
        "Our approach was based on data obtained from Hotjar, a powerful tool for receiving feedback, which gave us a way to understand who the user personas were and analyze current user sessions.",
      text2:
        "By redesigning the registration UI we increased the amount of information requested, encouraging users to enter more details and share their portfolio thanks to graphic features and the addition of detailed explanations on the benefits of additional information",
      text3:
        "Through online usability testing and AB testing, we were able to validate some of our insights, collecting positive feedback and ultimately improving the registration flow.",
    },
    researchTestRepeat: {
      title: "Research, design, test, repeat",
      text: "Our approach has shown that working in synergy with users, embracing change and listening to public responses are fundamental to the success of a digital product. District now offers a more advanced recruiting experience thanks to targeted changes that have redefined how users interact with the platform.",
    },
  },
};

export const l_district = (
  trad: DeepKeyof<(typeof traduction)[keyof typeof traduction]>,
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
