/** @jsxImportSource @emotion/react */
/*@ts-ignore */
import titleImg from "./images/title.png";
/*@ts-ignore */
import logoImg from "./images/logo_full.png";
/*@ts-ignore */
import logoFixedImg from "./images/logo_fixed.png";
/*@ts-ignore */
import logoMovingImg from "./images/logo_moving.png";
/*@ts-ignore */
import headerSide from "./images/header_side.png";
/*@ts-ignore */
import headerEllipse from "./images/header_ellipse.png";
/*@ts-ignore */
import headerWhiteFig from "./images/header_white_fig.png";

import { l_district } from "../../locales";
// import Lottie from "lottie-react";
import DistrictTypography from "../../theme/DistrictTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import { easings, useSpring, animated } from "react-spring";
import { useEffect } from "react";

const Header = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const [whiteFig, whiteFigApi] = useSpring(() => {
    return {
      from: {
        x: "0%",
        y: "0%",
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerWhiteFig = () => {
    whiteFigApi.start({
      to: [
        {
          x: "30%",
          y: "100%",
          rotate: 120,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          x: "5%",
          y: "100%",
          rotate: -180,

          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          x: "0%",
          y: "0%",
          rotate: 0,

          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
    });
  };

  const [redCircle, redCircleApi] = useSpring(() => {
    return {
      from: {
        top: "60%",
        left: "20%",
        skewY: "50px",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerRedCircle = () => {
    redCircleApi.start({
      to: [
        {
          top: "50%",
          left: "35%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "5%",
          left: "10%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          top: "60%",
          left: "20%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
    });
  };

  const [greenEllipse, greenEllipseApi] = useSpring(() => {
    return {
      from: {
        bottom: "0%",
        right: "8%",
        rotate: "-10deg",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });
  const triggerGreenEllipse = () => {
    greenEllipseApi.start({
      to: [
        {
          bottom: "55%",
          right: "0%",
          rotate: "290deg",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "40%",
          right: "15%",
          rotate: "65deg",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "0%",
          right: "8%",
          rotate: "-10deg",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  const [blackDot, blackDotApi] = useSpring(() => {
    return {
      from: {
        bottom: "20%",
        left: "20%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });
  const triggerBlackDot = () => {
    blackDotApi.start({
      to: [
        {
          bottom: "40%",
          left: "50%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "10%",
          left: "60%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          bottom: "20%",
          left: "20%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  useEffect(() => {
    whiteFigApi.stop();
    triggerWhiteFig();
    redCircleApi.stop();
    triggerRedCircle();
    greenEllipseApi.stop();
    triggerGreenEllipse();
    blackDotApi.stop();
    triggerBlackDot();
  }, []);

  return (
    <div className="mx-auto mb-10 mt-4 max-w-[1600px] px-4 py-5 md:mt-8 md:px-8 lg:px-12">
      <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
        {!isOverMd && (
          <img
            src={titleImg}
            alt=""
            style={{ width: "100%", maxWidth: "550px" }}
          />
        )}
        <div className="mb-10 flex h-fit w-full gap-10">
          <div className="relative mt-auto w-1/3 max-w-[160px]">
            <img
              src={logoImg}
              alt=""
              style={{
                width: "100%",
                bottom: 0,
                left: 0,
              }}
            />
          </div>
          {/* <div className="relative h-[142px] w-[142px] shrink-0">
            <img src={logoFixedImg} className="h-full w-full" />
            <div className="absolute right-[16px] top-[9px] h-[109px] w-[109px]">
              <img src={logoMovingImg} className="h-full w-full" />
            </div>
          </div> */}
          <div className="h-full w-full">
            {isOverMd && (
              <img
                src={titleImg}
                alt=""
                style={{ width: "100%", maxWidth: "550px" }}
              />
            )}
            <div className="mt-9 flex flex-col gap-[10px] md:mt-14">
              <div className="max-w-[400px] rounded-r-[28px] rounded-bl-[28px] bg-white px-6 py-5">
                <DistrictTypography
                  variant="subtitle"
                  className="text-[#555BFF]"
                >
                  {l_district("header.title1")}
                </DistrictTypography>
              </div>
              <div className="max-w-[400px] rounded-r-[28px] rounded-bl-[28px] bg-white px-6 py-5">
                <DistrictTypography
                  variant="subtitle"
                  className="text-[#555BFF]"
                >
                  {l_district("header.title2")}
                </DistrictTypography>
              </div>
            </div>
          </div>
        </div>
        <div className="relative min-h-10 w-full max-w-[800px]">
          <img src={headerSide} className="float-end w-[50%] pb-20 pr-[5%]" />
          <animated.div
            style={whiteFig}
            className="absolute aspect-[6/4] w-[40%]"
          >
            <img src={headerWhiteFig} className="w-full" />
          </animated.div>
          <animated.div
            style={redCircle}
            className={
              "absolute left-[20%] top-[60%] aspect-square w-[15%] rounded-full bg-[#E6431E]"
            }
          />
          <div className="absolute bottom-[20%] right-0 w-[15%]">
            <div className="relative aspect-square w-full rounded-full bg-[#F6CFD1]">
              <animated.div
                style={blackDot}
                className={
                  "absolute bottom-[20%] left-[20%] aspect-square w-[30%] rounded-full bg-[#000]"
                }
              />
            </div>
            <div className="relative -top-2 aspect-square w-full rounded-full bg-[#F6CFD1]" />
          </div>
          <animated.div
            style={greenEllipse}
            className="absolute bottom-[10%] right-[0%] h-fit w-[20%]"
          >
            <img src={headerEllipse} style={{ width: "100%" }} />
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default Header;
