import { animated, useSpring } from "react-spring";
//@ts-ignore
import buttonSpinning from "./images/button_spinning.png";
import { cn } from "../../../../../utils/cn";
//@ts-ignore
import buttonArrow from "./images/button_arrow.png";

const SlideScrollButton = ({
  onScroll,
  isLastSlide,
}: {
  onScroll: () => void;
  isLastSlide: boolean;
}) => {
  const [props] = useSpring(
    () => ({
      from: { rotate: 0 },
      to: { rotate: 360 },
      loop: true,
      config: { duration: 10_000 },
    }),
    [],
  );

  return (
    <div
      className="absolute right-10 top-1/2 z-30 h-[120px] w-[120px] -translate-y-1/2 hover:cursor-pointer"
      onClick={() => onScroll()}
    >
      <animated.div
        className="absolute left-0 top-0 h-[120px] w-[120px]"
        style={props}
      >
        <img src={buttonSpinning} className="h-full w-full" />
      </animated.div>
      <animated.div
        className={cn(
          "absolute left-[20px] top-[20px] flex h-[80px] w-[80px] items-center justify-center rounded-full transition-all duration-200 hover:bg-[rgba(255,255,255,0.12)] active:bg-[rgba(255,255,255,0.24)]",
          isLastSlide && "-rotate-180",
        )}
      >
        <img src={buttonArrow} className="h-[80%] w-[80%]" />
      </animated.div>
    </div>
  );
};

export default SlideScrollButton;
