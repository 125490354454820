import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";
import MultiColorText from "../../theme/MultiColorText";
import SpinningDecoration from "./SpinningDecoration";

const TextSection = () => {
  return (
    <div className="mx-auto max-w-[1500px] px-4 md:px-8 lg:px-12">
      <MabiParallax
        id="districtTextSection"
        duration={0.5}
        tweens={[
          {
            selector: "#text1",
            from: {
              opacity: "20%",
              y: "30%",
            },
            to: {
              opacity: "100%",
              y: "0%",
            },
          },
          {
            selector: "#text2",
            from: {
              opacity: "10%",
              y: "30%",
            },
            to: {
              delay: 0.5,
              opacity: "100%",
              y: "0%",
            },
          },
        ]}
      >
        <DistrictTypography
          variant="overtitle"
          className="max-w-[1100px] text-white/[0.7]"
        >
          {l_district("textSection.overtitle")}
        </DistrictTypography>
        <div className="relative max-w-[1300px]">
          <DistrictTypography
            variant="title1"
            className="relative z-20 mt-8 max-w-[1100px] md:mt-6"
            id="text1"
          >
            {l_district("textSection.section1")}
          </DistrictTypography>
          <SpinningDecoration />
        </div>
        <DistrictTypography
          variant="title1"
          className="mb-10 mt-10 max-w-[1100px] md:mb-16 md:mt-16"
          id="text2"
        >
          {l_district("textSection.section2_1")}
          <MultiColorText
            colors={[
              "#ED6159",
              "#76BB88",
              "#FFF",
              "#F2E500",
              "#F6CFD1",
              "#000",
              "#DAF1DA",
              "#F4C244",
            ]}
          >
            {l_district("textSection.section2_2")}
          </MultiColorText>
          {l_district("textSection.section2_3")}
        </DistrictTypography>
      </MabiParallax>
    </div>
  );
};

export default TextSection;
