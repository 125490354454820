import { ButtonBase, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { cn } from "../../../../../utils/cn";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";
import AscendingCard from "./AscendingCard";
import BeatingHeart from "./BeatingHeart";

const HeartSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isCardOpen, setIsCardOpen] = useState(false);

  return (
    <div className="mx-auto mt-10 max-w-[1100px] px-4 md:mt-20 md:px-8 lg:px-12">
      <div className="relative z-10 flex flex-col gap-4 overflow-hidden rounded-2xl bg-white px-6 py-8 md:flex-row md:gap-14 md:p-12">
        <BeatingHeart isCompressed={!isOverMd && isCardOpen} />
        <div
          className={cn(
            "relative box-border flex min-h-[300px] w-full flex-col justify-center text-center transition-all duration-500 md:static md:min-h-0 md:text-left",
            !isOverMd && isCardOpen && "min-h-[400px]",
          )}
        >
          <AscendingCard
            isShowing={isCardOpen}
            closeCard={() => {
              setIsCardOpen(false);
            }}
          />
          <div className="flex h-full flex-col md:min-h-[300px] md:justify-center">
            <DistrictTypography
              variant="title2"
              className="text-[6vw] leading-[calc(6vw+4px)] text-[#555BFF] sm:text-[40px] sm:leading-[48px]"
            >
              {l_district("heartSection.coverMessage1")}
            </DistrictTypography>
            <DistrictTypography
              variant="title2"
              className="text-[6vw] leading-[calc(6vw+4px)] text-[#555BFF] sm:text-[40px] sm:leading-[48px]"
            >
              {l_district("heartSection.coverMessage2")}
            </DistrictTypography>
            <DistrictTypography
              variant="title2"
              className="text-[6vw] leading-[calc(6vw+4px)] text-[#555BFF] sm:text-[40px] sm:leading-[48px]"
            >
              {l_district("heartSection.coverMessage3")}
            </DistrictTypography>
          </div>
          <div className="group relative mx-auto mb-4 mt-8 h-fit w-fit md:mb-2 md:mr-0">
            <ButtonBase
              onClick={() => setIsCardOpen(true)}
              disableRipple
              className="relative left-1 z-20 w-fit rounded-3xl border border-solid border-[#555BFF] bg-white px-6 py-3 text-[#555BFF] transition-all hover:top-[0.055rem] hover:bg-[#f7f7fb] active:top-1"
            >
              <Typography className="text-[18px] font-bold">
                {l_district("heartSection.buttonText")}
              </Typography>
            </ButtonBase>
            <span
              className={cn(
                "absolute left-2 top-2 h-full w-full rounded-3xl border border-[#555BFF] bg-[#555BFF]",
              )}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeartSection;
