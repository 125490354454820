import { animated, easings, useSpring } from "react-spring";

// @ts-ignore
import letterImg from "./images/letter.png";
// @ts-ignore
import pointerImg from "./images/l_pointer.png";
// @ts-ignore
import doubleImg from "./images/l_double.png";
import { useEffect } from "react";

const LetterDecoration = () => {
  const [doubleStyle, doubleApi] = useSpring(() => {
    return {
      from: {
        scale: 1,
        y: "-50%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerDouble = () => {
    doubleApi.start({
      to: [
        {
          scale: 0.6,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          scale: 1.2,
          delay: 1000,
          config: {
            duration: 1000,
          },
        },
        {
          scale: 1,
          config: {
            duration: 1000,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  const [pointerStyle, pointerApi] = useSpring(() => {
    return {
      from: {
        scale: 0.8,
        x: "0%",
        y: "0%",
        rotate: 0,
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
    };
  });

  const triggerPointer = () => {
    pointerApi.start({
      to: [
        {
          scale: 1,
          x: "-50%",
          y: "-25%",
          rotate: 0,
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          scale: 1,
          x: "-50%",
          y: "-25%",
          rotate: -20,
          config: {
            duration: 200,
          },
        },
        {
          scale: 0.8,
          x: "0%",
          y: "0%",
          delay: 1300,
          rotate: 0,
          config: {
            duration: 1500,
          },
        },
      ],
      loop: true,
      reverse: false,
    });
  };

  useEffect(() => {
    doubleApi.stop();
    triggerDouble();
    pointerApi.stop();
    triggerPointer();
  }, []);

  return (
    <div className="relative aspect-[628/430] h-full w-full">
      <div
        className={
          "absolute left-0 top-1/2 aspect-[697/650] w-[70%] -translate-y-1/2"
        }
      >
        <img src={letterImg} className="h-full w-full" />
      </div>
      <animated.div
        className={"absolute bottom-[0%] right-[5%] aspect-[1028/846] w-[30%]"}
        style={pointerStyle}
      >
        <img src={pointerImg} className="h-full w-full" />
      </animated.div>
      <animated.div
        className={
          "absolute right-[5%] top-[30%] aspect-[721/570] w-[25%] -translate-y-1/2"
        }
        style={doubleStyle}
      >
        <img src={doubleImg} className="h-full w-full" />
      </animated.div>
    </div>
  );
};

export default LetterDecoration;
