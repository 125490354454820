import { useState } from "react";
import { animated } from "react-spring";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";
// @ts-ignore
import imgGroup1 from "./images/group_1.png";
// @ts-ignore
import imgGroup2 from "./images/group_2.png";

const WhiteCardSection = () => {
  const [hasBeenAnimated, setHasBeenAnimated] = useState(false);
  return (
    <MabiParallax
      id="districtWhiteCardSection"
      tweens={
        hasBeenAnimated
          ? []
          : [
              {
                selector: "#districtAnimGroup1",
                from: {
                  scale: 0.8,
                  x: "-20%",
                },
                to: {
                  scale: 1,
                  x: "-60%",
                  delay: 0.5,
                  onComplete: () => {
                    setHasBeenAnimated(true);
                  },
                },
              },
              {
                selector: "#districtAnimGroup2",
                from: {
                  scale: 0.8,
                  x: "20%",
                  y: "10%",
                },
                to: {
                  scale: 1,
                  x: "60%",
                  y: "-15%",
                  delay: 0.5,
                  onComplete: () => {
                    setHasBeenAnimated(true);
                  },
                },
              },
            ]
      }
      style={{
        position: "relative",
        zIndex: 2,
      }}
    >
      <div className="relative my-24 overflow-hidden px-4 md:px-8 lg:px-12">
        <div className="relative mx-auto box-border flex min-h-[280px] max-w-[600px] items-center justify-center rounded-2xl bg-white px-10 py-8 text-center md:min-h-[380px] md:max-w-[780px] md:px-12 md:py-10">
          <DistrictTypography variant="title1" className="text-[#555BFF]">
            {l_district("incentivizeProfile.cardText")}
          </DistrictTypography>
          <animated.div
            className="absolute -left-0 top-0 h-full"
            id={hasBeenAnimated ? undefined : "districtAnimGroup1"}
          >
            <img src={imgGroup1} className="h-full" />
          </animated.div>
          <animated.div
            className="absolute right-0 top-0 h-full"
            id={hasBeenAnimated ? undefined : "districtAnimGroup2"}
          >
            <img src={imgGroup2} className="h-[80%]" />
          </animated.div>
        </div>
      </div>
    </MabiParallax>
  );
};

export default WhiteCardSection;
