import { animated, easings, useSpring } from "react-spring";
import { l_district } from "../../locales";
import DistrictTypography from "../../theme/DistrictTypography";

// @ts-ignore
import lensImg from "./images/lens_img.png";
// @ts-ignore
import checkboxImg from "./images/checkbox_img.png";
// @ts-ignore
import penImg from "./images/pen_img.png";
// @ts-ignore
import penDecImg from "./images/pen_dec_img.png";
// @ts-ignore
import carImg from "../TextSection/images/car_decoration.png";
// @ts-ignore
import eyeImg from "../TextSection/images/eye_decoration.png";
import { useEffect } from "react";

const ResearchTestRepeat = () => {
  const [lensProps, lensApi] = useSpring(() => {
    return {
      from: {
        rotate: -30,
        config: {
          duration: 2000,
          easing: easings.easeInOutCirc,
        },
      },
    };
  });

  const triggerLens = () => {
    lensApi.start({
      to: {
        rotate: 30,
        config: {
          duration: 2000,
          easing: easings.easeInOutCirc,
        },
      },

      delay: 2000,
      loop: true,
      reverse: true,
    });
  };

  const [eyeProps, eyeApi] = useSpring(() => {
    return {
      from: {
        top: "45%",
        right: "-10%",
        rotate: 0,
        config: {
          duration: 3000,
          easing: easings.easeInOutCirc,
        },
      },
    };
  });

  const triggerEye = () => {
    eyeApi.start({
      to: [
        {
          top: "-10%",
          right: "70%",
          rotate: 10,
          delay: 2000,
          config: {
            duration: 3000,
            easing: easings.easeInOutCirc,
          },
        },
        {
          top: "65%",
          right: "85%",
          rotate: -20,
          delay: 2000,
          config: {
            duration: 3000,
            easing: easings.easeInOutCirc,
          },
        },
        {
          top: "45%",
          right: "-10%",
          rotate: 0,
          delay: 2000,
          config: {
            duration: 3000,
            easing: easings.easeInOutCirc,
          },
        },
      ],
      loop: true,
      reverse: true,
    });
  };

  const [penProps, penApi] = useSpring(() => {
    return {
      from: {
        x: "-17%",
        y: "0%",
        rotate: -115,
        config: {
          duration: 800,
          easing: easings.easeInOutCirc,
        },
      },
    };
  });

  const triggerPen = () => {
    penApi.start({
      to: [
        {
          x: "5%",
          y: "-7%",
          rotate: -110,
          delay: 1000,
          config: {
            duration: 800,
            easing: easings.easeInOutCirc,
          },
        },
        {
          x: "10%",
          y: "0%",
          rotate: -115,
          delay: 1000,
          config: {
            duration: 800,
            easing: easings.easeInOutCirc,
          },
        },
        {
          x: "20%",
          y: "0%",
          rotate: -125,
          delay: 1000,
          config: {
            duration: 800,
            easing: easings.easeInOutCirc,
          },
        },
        {
          x: "-17%",
          y: "0%",
          rotate: -115,
          delay: 1500,
          config: {
            duration: 800,
            easing: easings.easeInOutCirc,
          },
        },
      ],
      loop: true,
      reverse: true,
    });
  };

  useEffect(() => {
    lensApi.stop();
    triggerLens();
    eyeApi.stop();
    triggerEye();
    penApi.stop();
    triggerPen();
  }, []);

  return (
    <div className="mx-auto mb-20 mt-10 box-border flex max-w-[1300px] flex-col-reverse items-center justify-between gap-10 px-4 md:mb-20 md:mt-20 md:flex-row md:px-8 lg:px-12">
      <div className="w-full">
        <DistrictTypography variant="title1" className="mb-8">
          {l_district("researchTestRepeat.title")}
        </DistrictTypography>
        <DistrictTypography variant="body">
          {l_district("researchTestRepeat.text")}
        </DistrictTypography>
      </div>
      <div className="relative mx-auto aspect-[625/558] w-full max-w-[580px] md:max-w-full">
        <div className="absolute left-[15%] w-[30%]">
          <animated.div className={"relative z-[2]"} style={lensProps}>
            <img src={lensImg} className="w-full" />
          </animated.div>
          <div className="absolute right-[-65%] top-[35%] w-[85%] -rotate-[10deg]">
            <img src={carImg} className="w-full" />
          </div>
        </div>
        <div className="absolute -bottom-[1%] left-[5%] w-[35%]">
          <div className={"relative"}>
            <img src={checkboxImg} className="w-full" />
          </div>
          <animated.div
            className="absolute right-[-10%] top-[55%] z-[2] w-[45%] -rotate-[10deg]"
            style={eyeProps}
          >
            <img src={eyeImg} className="w-full" />
          </animated.div>
        </div>
        <div className="absolute right-0 top-[40%] w-[30%]">
          <animated.div
            className={"relative z-[2] rotate-[-110deg]"}
            style={penProps}
          >
            <img src={penImg} className="w-full" />
          </animated.div>
          <div className="absolute -bottom-[30%] left-[-20%] w-[85%] -rotate-[0deg]">
            <img src={penDecImg} className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchTestRepeat;
