import useEmblaCarousel from "embla-carousel-react";
//@ts-ignore
import img1 from "./images/1.png";
//@ts-ignore
import img2 from "./images/2.png";
//@ts-ignore
import img3 from "./images/3.png";
//@ts-ignore
import img4 from "./images/4.png";
//@ts-ignore
import img5 from "./images/5.png";

import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { cn } from "../../../../../utils/cn";
import SlideScrollButton from "./SlideScrollButton";
import SlideScrollButtonSmall from "./SlideScrollButtonSmall";

const SlideSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const slides = [img1, img2, img3, img4, img5];

  const [currentSlide, setCurrentSlide] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: !isOverMd,
    startIndex: 0,
    speed: 100,
  });

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCurrentSlide(emblaApi.selectedScrollSnap());
    };

    emblaApi.on("select", onSelect);

    onSelect();

    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi]);

  const onNextSlideClick = () => {
    if (emblaApi?.canScrollNext()) {
      emblaApi?.scrollNext();
    } else {
      emblaApi?.scrollTo(0);
    }
  };

  return (
    <div className="relative" key={isOverMd ? "md" : "sm"}>
      <div className="pointer-events-none absolute right-0 top-0 z-20 h-full min-h-full w-[50%] select-none bg-gradient-to-l from-[#5560f6ce] to-[#5560F600]"></div>
      {isOverMd && (
        <SlideScrollButton
          onScroll={onNextSlideClick}
          isLastSlide={!emblaApi?.canScrollNext()}
        />
      )}
      <div
        className="relative w-full overflow-x-hidden py-10 shadow-[inner_30px_black] md:py-20"
        ref={emblaRef}
      >
        <div className="relative flex w-full gap-0 md:transition-transform md:duration-500">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={cn(
                "max-w-[1000px] flex-[0_0_100%] cursor-grab drop-shadow-[10px_15px_20px_rgba(0,0,0,0.30)] active:cursor-grabbing md:flex-[0_0_80%] md:cursor-default md:active:cursor-default",
              )}
              style={{ zIndex: index + 1 }}
            >
              <img
                src={slide}
                className={cn(
                  "relative box-border w-full px-4 transition-transform duration-700 md:px-0",
                  index > currentSlide && "scale-75 md:-translate-x-[40%]",
                  index < currentSlide && "scale-75 md:translate-x-[40%]",
                )}
              />
            </div>
          ))}
        </div>
        {!isOverMd && <SlideScrollButtonSmall />}
      </div>
    </div>
  );
};

export default SlideSection;
