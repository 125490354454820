import { useState } from "react";
import WhiteCardSection from "./WhiteCardSection";
import DistrictTypography from "../../theme/DistrictTypography";
import { l_district } from "../../locales";
// @ts-ignore
import screenImg from "./images/screen.png";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { useMediaQuery, useTheme } from "@mui/material";

const IncentivizeProfile = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const tweens = [
    {
      selector: "#district-registration-title",
      from: {
        opacity: 0,
        y: "30%",
      },
      to: {
        opacity: 1,
        y: "0%",
      },
    },
    {
      selector: "#district-registration-text-1",
      from: {
        opacity: 0,
        y: "30%",
      },
      to: {
        opacity: 1,
        y: "0%",
        delay: 0.3,
      },
    },
    {
      selector: "#district-registration-text-2",
      from: {
        opacity: 0,
        y: "30%",
      },
      to: {
        opacity: 1,
        y: "0%",
        delay: 0.6,
      },
    },
    {
      selector: "#district-registration-text-3",
      from: {
        opacity: 0,
        y: "30%",
      },
      to: {
        opacity: 1,
        y: "0%",
        delay: 0.8,
      },
    },
    {
      selector: "#district-imgCover",
      from: { opacity: "100%", height: "50%" },
      to: {
        opacity: isOverMd ? "0%" : "100%",
        height: isOverMd ? "80%" : "90%",
      },
      delay: 0.8,
    },
  ];

  return (
    <div>
      <WhiteCardSection />
      <MabiParallax id={"districtIncentivizeProfile"} tweens={tweens}>
        <div className="mx-auto my-10 box-border flex max-w-[1300px] flex-col items-center justify-between gap-10 px-4 md:flex-row md:px-8 lg:px-12">
          <div className="flex w-full items-center justify-center self-stretch">
            <div className="relative">
              <div
                id="district-imgCover"
                className="absolute bottom-0 h-[50%] w-full bg-gradient-to-t from-[rgb(85,91,255)] from-30% to-transparent md:from-0%"
              />
              <img src={screenImg} className="h-full w-full py-4" />
            </div>
          </div>
          <div className="relative -top-[200px] -mb-[200px] w-full md:top-0 md:mb-0">
            <DistrictTypography
              variant="title1"
              className="mb-8"
              id="district-registration-title"
            >
              {l_district("incentivizeProfile.title")}
            </DistrictTypography>
            <DistrictTypography
              variant="body"
              className="mb-4"
              id="district-registration-text-1"
            >
              {l_district("incentivizeProfile.text1")}
            </DistrictTypography>
            <DistrictTypography
              variant="body"
              className="mb-4"
              id="district-registration-text-2"
            >
              {l_district("incentivizeProfile.text2")}
            </DistrictTypography>
            <DistrictTypography
              variant="body"
              className=""
              id="district-registration-text-3"
            >
              {l_district("incentivizeProfile.text3")}
            </DistrictTypography>
          </div>
        </div>
      </MabiParallax>
    </div>
  );
};

export default IncentivizeProfile;
