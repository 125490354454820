import { Typography, TypographyProps } from "@mui/material";

type DistrictTypographyProps = {
  variant?: "title1" | "overtitle" | "subtitle" | "span" | "title2" | "body";
} & Omit<TypographyProps, "variant" | "color">;

const DistrictTypography: React.FC<DistrictTypographyProps> = ({
  variant,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={variant === "span" ? "span" : "p"}
      sx={{
        color: "#FFF",
        ...(variant === "subtitle"
          ? {
              fontSize: { md: "20px", xs: "16px" },
              lineHeight: { md: "28px", xs: "24px" },
              fontWeight: 400,
            }
          : variant === "title1"
            ? {
                fontSize: { md: "48px", lg: "60px", xs: "40px" },
                lineHeight: { md: "52px", lg: "68px", xs: "44px" },
                fontWeight: 800,
              }
            : variant === "title2"
              ? {
                  fontSize: { md: "40px", xs: "24px" },
                  lineHeight: { md: "48px", xs: "32px" },
                  fontWeight: 800,
                }
              : variant === "overtitle"
                ? {
                    fontSize: { md: "40px", xs: "32px" },
                    lineHeight: { md: "44px", xs: "36px" },
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }
                : variant === "body"
                  ? {
                      fontSize: { md: "20px", xs: "20px" },
                      lineHeight: { md: "28px", xs: "28px" },
                      fontWeight: 800,
                    }
                  : variant === "span"
                    ? {
                        fontSize: "inherit",
                        lineHeight: "inherit",
                        fontWeight: "inherit",
                        color: "inherit",
                      }
                    : {}),
        ...sx,
      }}
      {...props}
    />
  );
};
export default DistrictTypography;
