import React from "react";
import DistrictTypography from "../../theme/DistrictTypography";
import { l_district } from "../../locales";
import MultiColorText from "../../theme/MultiColorText";
import YellowLineDecoration from "./YellowLineDecoration";
import { useMediaQuery, useTheme } from "@mui/material";
import useResizeObserver from "use-resize-observer";
import LetterDecoration from "./LetterDecoration";

const BeginningWith = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const { ref, width, height } = useResizeObserver();

  return (
    <div className="relative my-20 md:my-20">
      {/* <div className="relative flex justify-between gap-4"> */}
      <div className="relative mx-auto flex w-full flex-col-reverse items-center md:block">
        <div className="mx-auto box-border flex max-w-[1300px] justify-between px-4 md:px-8 lg:px-12">
          <div className="relative z-[2] mx-auto w-full max-w-[580px] shrink-0 md:ml-0 md:mr-auto md:max-w-[500px]">
            <DistrictTypography variant="title1" className="mb-8 max-w-[450px]">
              {l_district("beginningWith.titleA")}
            </DistrictTypography>
            <DistrictTypography variant="body" className="mb-6">
              {l_district("beginningWith.textA_1")}
            </DistrictTypography>
            <DistrictTypography variant="body">
              {l_district("beginningWith.textA_2")}
            </DistrictTypography>
          </div>
          <div className="aspect-[1450/1556] w-full" ref={ref} />
        </div>
        <YellowLineDecoration width={width ?? 0} height={height ?? 0} />
      </div>
      {/* </div> */}
      <div className="mx-auto mt-10 flex max-w-[1300px] flex-col items-center gap-10 px-4 md:flex-row md:px-8 lg:px-12">
        <div className="w-full max-w-[580px] md:max-w-full">
          <LetterDecoration />
        </div>
        <div className="max-w-[580px]">
          <DistrictTypography variant="title1" className="mb-8 max-w-[580px]">
            {l_district("beginningWith.titleB_1")}
            <MultiColorText
              colors={[
                "#FFF",
                "#76BB88",
                "#ED6159",
                "#DAF1DA",
                "#000",
                "#F2E500",
                "#ED6159",
                "#ED6159",
              ]}
            >
              {l_district("beginningWith.titleB_2")}
            </MultiColorText>
            {l_district("beginningWith.titleB_3")}
          </DistrictTypography>
          <DistrictTypography variant="body" className="mb-0">
            {l_district("beginningWith.textB_1")}
          </DistrictTypography>
          <DistrictTypography variant="body">
            {l_district("beginningWith.textB_2")}
          </DistrictTypography>
        </div>
      </div>
    </div>
  );
};

export default BeginningWith;
