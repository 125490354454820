import DistrictTypography from "./DistrictTypography";

const MultiColorText = ({
  children,
  colors,
}: {
  children: string;
  colors: string[];
}) => {
  return (
    <DistrictTypography variant="span">
      {children.split("").map((letter, i) => (
        <DistrictTypography
          key={i}
          variant="span"
          sx={{ color: colors.length ? colors[i % colors.length] : "inherit" }}
        >
          {letter}
        </DistrictTypography>
      ))}
    </DistrictTypography>
  );
};

export default MultiColorText;
